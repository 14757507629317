import React, { memo, useState, useEffect, useMemo, useCallback, useRef } from 'react';

//Libraries
import pluralize from 'pluralize';
import moment from 'moment';
import clsx from 'clsx';
import Link from 'next/link';

//Import methods
import useCompleteBooking from '@/utils/customHooks/useCompleteBooking';
import { PropertyTypes } from 'utils';
import useDateFormatter from '@/containers/homePage/Omnisearch/useDateFormatter';
import useGenerateLink from '@/utils/customHooks/useGenerateLink';
import { config } from '@/utils/config';
import { PDFilterSchema } from 'utils/CustomHooks/usePDCommonState';
import useDeviceDetect from '@/src/utils/customHooks/useDeviceDetect';

//Components
import Image from '@/containers/newHomePage/components/Image';
import BedOutlinedIcon from '../common/icons/BedOutlined';
import HouseOutlinedIcon from '../common/icons/HouseOutlined';
import CloseIcon from '../common/icons/Close';
import CaretIcon from '../common/icons/Caret';
import BooleanTrigger from '@/components/BooleanTrigger';
import LogoBirds from '../common/logoBirds';



const CompleteBookingCTA = ({ displayTimeout, expirationThreshold }) => {
    const {isMobile , isTablet} = useDeviceDetect();

    //States
    const { getData, removeData } = useCompleteBooking();
    const [data, setData] = useState(() => typeof window !== 'undefined' && getData());
    const [showCTA, setShowCTA] = useState(false);
    const [visibility, setVisibilityState] = useState(false);
    const [qrScroll, setQrScroll] = useState(false);
    const [cookieWrapper, setCookieWrapper] = useState(false);


    //Vars
    const dateFormater = useDateFormatter();
    const generateLink = useGenerateLink();




    //--- enter animation
    useEffect(() => {
        const visibilityTimeout = 500;

        const timeoutId = setTimeout(() => {
            setShowCTA(true);
        }, Math.max(visibilityTimeout, displayTimeout));

        const readyTimerId = setTimeout(() => {
            setVisibilityState(true);
        }, visibilityTimeout);

        return () => {
            clearTimeout(timeoutId);
            clearTimeout(readyTimerId);
        }
    }, [displayTimeout]);

    //--- close animation
    const hideCTA = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        setShowCTA(false);

        setTimeout(() => {
            setData(removeData());
        }, 700);
    }, [removeData]);

    //--- date calculation
    const getDate = useCallback((date, format = 'DD MMM') => dateFormater(moment(date), format), []);

    const isWidgetExpired = (lastViewedAt = Date.now()) =>
        () => moment(lastViewedAt)
                .add(expirationThreshold, 'hours')
                .isBefore(Date.now());


    //--- set icon
    const Icon = useMemo(() => {
        if (data?.propertyType?.toLowerCase() === PropertyTypes.HOTEL) {
            return BedOutlinedIcon;
        }
        return HouseOutlinedIcon;
    }, [data]);


    const getRuntimeClassName = useCallback(
      () => {
        // This authored by Biniman
        return `${(cookieWrapper || (!qrScroll && Boolean(data)) ) ? (`${cookieWrapper && isTablet ? `with_cookie ${qrScroll ? 'isScrolled ': ''}` : ''}`) : ''}`
      },
      [cookieWrapper, qrScroll, data],
    )


    //--- guest calculation
    const guestCount = useMemo(() => {
        if (data) {
            return [data.adults, data.children, data.infants].reduce((acc, current) => {
                const num = Number.parseInt(current);
                if (num) {
                    return acc + num;
                }
                return acc;
            }, 0);
        }
        return 0;
    }, [data]);

    useEffect(()=>{
        const getHeroElement = document.querySelector('.cn__image-picture');
        const windowScroll = () => {
            const getScrollY = window.scrollY;
            if((getHeroElement && (getScrollY>(getHeroElement?.clientHeight)/2))){
                setQrScroll(true)
            } else {
                setQrScroll(false)
            }
        }
        window.addEventListener('scroll',windowScroll)
        return ()=>{
            window.removeEventListener('scroll',windowScroll)
        }
    },[])

    if (!visibility) {
        return null;
    }
    const  getMobileOperatingSystem = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }

    const downloadApplication = () => {
        const getOS = getMobileOperatingSystem();

        if(getOS === "Android") {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.cuddlynest'
            return;
        }
        if(getOS === "iOS") {
            window.location.href = 'https://apps.apple.com/app/cuddlynest-hotels-and-homes/id1504837568?uo=2'
            return;
        }

        window.location.href = '/'
    }
    return <>{Boolean(data) && (
        <div className='cn__CTA__completebooking__wrapper'>
            <Link
                href={generateLink({
                    baseUrl: `${config.current_url}${data.productUrl}`,
                    queryParams: [
                        [PDFilterSchema.checkin, dateFormater(moment(data.checkin))],
                        [PDFilterSchema.checkout, dateFormater(moment(data.checkout))],
                        [PDFilterSchema.adults, data.adults],
                        [PDFilterSchema.children, data.children],
                        [PDFilterSchema.infants, data.infants],
                        [PDFilterSchema.rooms, data.rooms]
                    ]
                })}
            >
                <div className={clsx('cn__CTA__completebooking', showCTA && 'cn__CTA__completebooking--show')}>
                <div className='cn__CTA__completebooking__inner'>
                    <div className='cn__CTA__completebooking__inner__img'>
                        <Image src={data.productImage} alt={data.productId} />
                    </div>
                    <div className='cn__CTA__completebooking__inner__info'>
                        <div className='cn__CTA__completebooking__inner__info__header'>
                            <div className='cn__CTA__completebooking__inner__info__header__div'>
                                <Icon className='cn__CTA__completebooking__inner__info__header__propertyicon' />
                                <p className='cn__CTA__completebooking__inner__info__header__title' dangerouslySetInnerHTML={{ __html: data.propertyTitle }} />
                            </div>
                            <button className='cn__CTA__completebooking__inner__info__header__close' onClick={hideCTA}>
                                <CloseIcon />
                            </button>
                        </div>
                        <p className='cn__CTA__completebooking__inner__info__description'>
                            {`${getDate(data.checkin)} - ${getDate(data.checkout)} | ${pluralize('guest', guestCount || 1, true)}`}
                        </p>
                        <div className='cn__CTA__completebooking__inner__info__book'>
                            <div className='cn__CTA__completebooking__inner__info__book__wrapper'>
                                <p className='cn__CTA__completebooking__inner__info__book__wrapper__title'>
                                    <BooleanTrigger
                                        getPredicate={isWidgetExpired(data.lastViewedAt)}
                                        truthySlot='Recently viewed'
                                        falsySlot='Complete booking'
                                    />
                                </p>
                                <CaretIcon />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </Link>
        </div>
    )}

    <div className={clsx('cn__hp__download_wrapper', 'mui-fixed')}>
        {isMobile
            ?
            <div className={`cn__hp__download__floating__mobile__container `} onClick={downloadApplication}>
                <span className="cn__hp__download__floating__text cn__hp__download__floating__mobile"><LogoBirds /> GET THE APP.</span>
            </div>
            :
            <div className={clsx('cn__hp__download__floating', !isTablet && getRuntimeClassName())}>
                <img src="/static/staticpages/home/download/qr3.svg" alt="QR image" className='cn__hp__download__info__buttons__qr'/>
                <span className="cn__hp__download__floating__text">Get the App.</span>
            </div>
        }
    </div>

            {/* {!isMobile ?
              <div className={clsx('cn__hp__download__floating', 'mui-fixed', !isTablet && getRuntimeClassName())}>
                  <img src="/static/staticpages/home/download/qr3.svg" alt="QR image" className='cn__hp__download__info__buttons__qr'/>
                 <span className="cn__hp__download__floating__text">Get the App.</span>
              </div>
                :
              <div className={`cn__hp__download__floating__mobile__container `} onClick={downloadApplication}>
                 <span className="cn__hp__download__floating__text cn__hp__download__floating__mobile"><LogoBirds /> GET THE APP.</span>
              </div>
            } */}
    </>;
};

CompleteBookingCTA.defaultProps = {
    displayTimeout: 1600,
    expirationThreshold: 24 // 24 h
};

export default memo(CompleteBookingCTA);